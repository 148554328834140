<template>
  <div class="upload">
    <el-upload
      class="upload-demo"
      drag
      :action="UploadUrl()"
      :before-upload="beforeUploadFile"
      :on-change="fileChange"
      :on-remove="handleRemove"
      :file-list="fileList"
      :http-request="uploadFile"
      :disabled="fileDisabled"
      v-loading="loadingDialog"
    >
      <!-- :on-exceed="exceedFile" -->
      <img
        style="width: 100%"
        v-if="fileObj.url && showimg"
        :src="fileObj.url"
      />
      <el-icon v-if="!showimg || !fileObj.url" class="el-icon--upload">
        <I name="UploadFilled"></I>
      </el-icon>
      <div v-if="!showimg || !fileObj.url" class="el-upload__text">
        将文件拖拽，或<em>点击上传</em>
      </div>
      <!-- <div class="remove" v-if="fileObj.url" @click.stop="handleRemove">x</div> -->
      <!-- <template #tip>
        <div class="el-upload__tip">建议尺寸：1125*1125,可拖拽改变图片顺序，默认首张图为主图，最多可上传9张</div>
      </template> -->
    </el-upload>
  </div>
</template>

<script setup>
import { reactive, toRefs, defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { putObject } from '@/api/eventManagement'
const OSS = require('ali-oss')
defineProps({
  showimg: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['getimglist'])

const initData = reactive({
  // 文件
  fileList: [],
  fileObj: {},
  fullscreenLoading: false,
  ossObj: '',
  loadingDialog: false,
  fileDisabled: false
})
const {
  fileList,
  fileObj,
  fullscreenLoading,
  ossObj,
  loadingDialog,
  fileDisabled
} = toRefs(initData)
let client = null
// 文件超出个数限制时的钩子
// const exceedFile = (files, fileList) => {
//   ElMessage.warning(`只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`);
// };

// 文件状态改变时的钩子
const fileChange = file => {
  fileList.value = []
  fileList.value.push(file.raw)
}

const asyncImgChecked = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file) // 必须用file.raw
    reader.onload = () => {
      // 让页面中的img标签的src指向读取的路径
      let img = new Image()
      img.src = reader.result
      img.onload = () => {
        if (img.width != 1126 || img.height != 1125) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}

// 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
const beforeUploadFile = async file => {
  let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
  // let size = file.size / 1024 / 1024;
  if (extension != 'png' && extension != 'jpg' && extension != 'jpeg') {
    ElMessage.warning('只能上传后缀是png、jpg、jpeg的文件')
    return false
  }
  // let res = await asyncImgChecked(file);
  // if (!res) {
  //   ElMessage.warning("请上传1126*1125比例图片");
  //   return false;
  // }
}

const handleRemove = () => {
  fileList.value = []
  fileObj.value.url = ''
  emit('getimglist', fileObj.value.url)
}

const UploadUrl = () => {
  // 因为action参数是必填项，我们使用二次确认进行文件上传时，直接填上传文件的url会因为没有参数导致api报404，所以这里将action设置为一个返回为空的方法就行，避免抛错
  return ''
}

// 上传
const uploadFile = option => {
  // 获取文件的后缀名
  fullscreenLoading.value = true
  putObject({ fileName: option.file.name }).then(({ data: res }) => {
    if (res.code == 200) {
      ossObj.value = res.data
      client = new OSS({
        region: res.data.regionId,
        secure: true,
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        bucket: res.data.bucketName,
        cname: true,
        endpoint: `https://${res.data.cdnDomain}`, 
      })
      put(`${res.data.dirPath + res.data.fileName}`, option.file).then(
        ({ res: data }) => {
          fullscreenLoading.value = false
          if (data.status == 200) {
            fileObj.value.url = data.requestUrls[0]
            emit('getimglist', fileObj.value.url)
          } else {
            ElMessage.warning('上传失败')
          }
        }
      )
    }
  })
}

/**
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 * @retruns Promise
 */

const put = async (ObjName, fileUrl) => {
  try {
    let result = await client.put(`${ObjName}`, fileUrl)
    // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称
    return result
  } catch (e) {
    console.log(e)
  }
}
</script>
<style lang="scss">
.upload-demo,
.el-upload,
.el-upload-dragger {
  width: 100%;
  height: 100%;
  padding: 0;
}

.el-upload-list {
  display: none;
}

.el-upload-dragger {
  overflow: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.remove {
  position: absolute;
  right: -8px;
  top: -15px;
  font-weight: bold;
  font-size: 22px;
  color: red;
}

.el-upload-dragger .el-icon--upload {
  height: auto;
  line-height: normal;
}

.el-upload__tip {
  margin-top: 0;
  width: 500px;
}
</style>
