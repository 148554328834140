<template>
  <div class="productItem">
    <div class="top">
      <h6>商品管理</h6>
      <div class="back" @click="cancel">返回上一级</div>
    </div>
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="基础设置" name="basicSettings">
        <div class="productform">
          <div class="item">
            <span><i>*</i>商品货号</span>
            <div class="content">
              <el-input placeholder="请输入" v-model="formData.code"></el-input>
            </div>
          </div>
          <div class="item otherItem">
            <span><i>*</i>商品名称</span>
            <div class="content">
              <el-input
                  v-model="formData.name"
                  type="textarea"
                  :rows="3"
                  :maxlength="24"
                  placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>商品类型</span>
            <div class="content">
              <el-select
                  v-model="formData.type"
                  placeholder="请选择"
                  @change="handleChangeType"
              >
                <el-option
                    v-for="item in shopTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <!-- 有赞商品 -->
          <div class="item" v-if="formData.type == 3">
            <span><i>*</i>有赞商品跳转路径</span>
            <div class="content">
              <el-input
                  placeholder="请输入"
                  v-model="formData.refContent"
              ></el-input>
              <el-button link type="primary" @click="gotofeishu">
                如何找到有赞商品对应跳转路径
              </el-button>
            </div>
          </div>

          <!-- 虚拟券 -->
          <div class="item" v-if="formData.type == 4">
            <span><i>*</i>关联虚拟券</span>
            <div class="content">
              <el-select
                  v-model="formData.refId"
                  filterable
                  remote
                  placeholder="可按名称模糊匹配"
                  :remote-method="remoteMethod"
                  @change="handleChangeRefId"
              >
                <el-option
                    v-for="item in refIdOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>商品分类</span>
            <div class="content">
              <el-cascader
                  class="prdouctcategory"
                  v-model="formData.categoryIdList"
                  :options="treeList"
                  :props="cateOpt"
                  clearable
                  filterable
                  placeholder="请选择"
              />
            </div>
          </div>
          <div class="item">
            <span><i>*</i>购买等级限制</span>
            <div class="content">
              <el-select v-model="formData.level" placeholder="请选择">
                <el-option
                    v-for="item in levelList"
                    :key="item.code"
                    :label="item.value"
                    :value="item.code"
                />
              </el-select>
              起
            </div>
          </div>
          <div class="item otherItem">
            <span><i>*</i>商品轮播图</span>
            <div class="content swiperContent">
              <div class="imglist">
                <draggable
                    v-model="formData.goodsImageList"
                    animation="200"
                    ref="goodsImage"
                    item-key="item"
                >
                  <template #item="{ element: item, index }">
                    <div class="itemImg">
                      <img :src="item" class="img"/>
                      <img
                          src="../../assets/img/deleteimg.svg"
                          class="deleteimg"
                          @click="deleteImage(index)"
                      />
                      <div class="change">
                        <span @click="editImage(index)">修改</span>
                        <span @click="openImagePreview(index)">查看</span>
                      </div>
                    </div>
                  </template>
                </draggable>
                <div
                    class="upload"
                    v-show="formData.goodsImageList.length < 10"
                >
                  <fileUpload
                      @getimglist="getimglist"
                      ref="uploadFilesDom"
                      :showimg="false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <span></span>
            <div class="content uploadtips">
              <div>
                建议尺寸：1125*1125,可拖拽改变图片顺序，默认首张图为主图，最多可上传10张
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="价格/库存" name="priceInventory">
        <div class="productform">
          <div class="item">
            <span><i>*</i>商品规格</span>
            <div class="content">
              <el-radio-group v-model="formData.specType">
                <el-radio :label="1" size="large">单规格</el-radio>
                <!-- <el-radio :label="2" size="large">多规格</el-radio> -->
              </el-radio-group>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>支付方式</span>
            <div class="content">
              <el-select
                  v-model="formData.payType"
                  placeholder="请选择"
                  @change="payTypeChange"
              >
                <el-option
                    v-for="item in payTypeList"
                    :disabled="item.disabled"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>商品价格</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.price"
                  @keyup="priceChange"
                  @blur="priceBlur"
                  placeholder="请输入"
              >
                <template #append>{{
                    formData.payType == 1 ? '元' : '积分'
                  }}
                </template>
              </el-input>
            </div>
          </div>
          <div class="item">
            <span>划线价</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.linePrice"
                  @keyup="priceChange"
                  @blur="priceBlur"
                  placeholder="请输入"
              >
                <template #append>{{
                    formData.payType == 1 ? '元' : '积分'
                  }}
                </template>
              </el-input>
            </div>
          </div>
          <div class="item">
            <span>成本价</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.marketPrice"
                  @keyup="priceChange"
                  @blur="priceBlur"
                  placeholder="请输入"
              >
                <template #append>{{
                    formData.payType == 1 ? '元' : '积分'
                  }}
                </template>
              </el-input>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>库存</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.stock"
                  @keyup="stockChange"
                  placeholder="请输入"
                  :disabled="formData.type == 4 && sendTotalType == 2"
              >
              </el-input>
              <el-button
                  v-if="formData.type == 4 && sendTotalType == 2"
                  type="primary"
                  link
              >
                商品库存将自动同步卡券数量
              </el-button>
            </div>
          </div>
          <div class="item">
            <span>体积</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.volume"
                  @keyup="priceChange"
                  @blur="priceBlur"
                  placeholder="请输入"
              >
                <template #append>m3</template>
              </el-input>
            </div>
          </div>
          <div class="item">
            <span>重量</span>
            <div class="content">
              <el-input
                  v-model="formData.goodsSku.weight"
                  @keyup="priceChange"
                  @blur="priceBlur"
                  placeholder="请输入"
              >
                <template #append>KG</template>
              </el-input>
            </div>
          </div>
          <div class="item">
            <span>条形码</span>
            <div class="content">
              <el-input v-model="formData.goodsSku.code" placeholder="请输入">
              </el-input>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="物流设置" name="logisticsSettings">
        <div class="productform">
          <!-- <div class="item delivery">
            <span>配送方式</span>
            <div class="content">
              <el-checkbox-group :model-value="['1']">
                <el-checkbox label="1">快递配送</el-checkbox>
              </el-checkbox-group>
              <div class="form-tips">必须选择一种配送方式</div>
            </div>
          </div> -->
          <div class="item delivery">
            <span><i>*</i>运费设置</span>
            <div class="content">
              <el-radio-group
                  v-model="formData.deliveryType"
                  style="margin-right: 30px"
              >
                <el-radio
                    :label="1"
                    size="large"
                    :disabled="formData.type == 4"
                >
                  包邮
                </el-radio>
                <el-radio
                    :label="2"
                    size="large"
                    :disabled="formData.type == 4"
                >
                  自提
                </el-radio>
                <el-radio
                    :label="3"
                    size="large"
                    :disabled="formData.type != 4"
                >
                  立即发放
                </el-radio>
              </el-radio-group>
              <!-- <el-select
                v-if="formData.deliveryType == 3"
                v-model="formData.expressTemplateId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in deliveryTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select> -->
            </div>
          </div>
          <div class="item otherItem" v-if="formData.deliveryType == 2">
            <span><i>*</i>取餐地点</span>
            <div class="content">
              <el-input
                  placeholder="请输入"
                  type="textarea"
                  :rows="4"
                  v-model="formData.pickUpAddress"
              >
              </el-input>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品详情" name="details">
        <div class="detailsform">
          <div class="item">
            <span>商品属性</span>
            <span class="addhj" @click="addJF">点击添加商品属性</span>
          </div>
          <div class="item property">
            <span></span>
            <div class="content">
              <div>
                <draggable
                    v-model="formData.propertyList"
                    animation="200"
                    ref="goodsProperty"
                    item-key="item"
                >
                  <template #item="{ element: item, index }">
                    <div class="cont">
                      <span>属性名称：</span>
                      <el-input
                          v-model="item.label"
                          type="text"
                          placeholder="请输入"
                          class="dialog_input"
                          :maxlength="10"
                      ></el-input>
                      <span>属性值：</span>
                      <el-input
                          v-model="item.value"
                          type="text"
                          placeholder="请输入"
                          class="dialog_input"
                      ></el-input>
                      <I
                          name="RemoveFilled"
                          class="delete"
                          @click="deleteJF(index)"
                      ></I>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
          <div class="item">
            <span><i>*</i>商品详情</span>
            <div class="content">
              <QuillEditor
                  class="myQuillEditor"
                  ref="quillRef"
                  theme="snow"
                  v-model:content="formData.content"
                  :options="editorOption"
                  contentType="html"
              />
            </div>
            <div class="contenthtml" v-html="formData.content"></div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="销售设置" name="salesSettings">
        <div class="productform">
          <div class="item">
            <span>库存预警</span>
            <div class="content">
              <el-input
                  v-model="formData.stockWarning"
                  @keyup="stockChange"
                  placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <div class="item">
            <span></span>
            <div class="content">
              <span>设置最低库存预警值，不填或填0表示不做库存预警</span>
            </div>
          </div>
          <div class="item">
            <span>虚拟销量</span>
            <div class="content">
              <el-input
                  v-model="formData.virtualSalesNum"
                  @keyup="stockChange"
                  placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <!-- <div class="item">
            <span>虚拟浏览量</span>
            <div class="content">
              <el-input> </el-input>
            </div>
          </div> -->
          <div class="item">
            <span>排序</span>
            <div class="content">
              <el-input
                  v-model="formData.sort"
                  @keyup="stockChange"
                  placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
          <div class="item">
            <span></span>
            <div class="content">
              <span>默认为0，数值越大越排前</span>
            </div>
          </div>
          <div class="item" v-if="false">
            <span>是否限时</span>
            <div class="content">
              <el-radio-group
                  v-model="formData.seckillGoods.limitTime"
                  @change="limitTimechange"
              >
                <el-radio :label="true" size="large">是</el-radio>
                <el-radio :label="false" size="large">否</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- v-if="formData.seckillGoods.limitTime" -->
          <div class="item" v-if="false">
            <span><i>*</i>限时时间</span>
            <div class="content">
              <el-date-picker
                  v-model="formData.seckillGoods.time"
                  type="datetimerange"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  range-separator="至"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  clearable
              />
            </div>
          </div>
          <div class="item" v-if="false">
            <span>是否限购</span>
            <div class="content">
              <el-radio-group
                  v-model="formData.seckillGoods.limitBuy"
                  @change="limitBuychange"
              >
                <el-radio :label="true" size="large">是</el-radio>
                <el-radio :label="false" size="large">否</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- v-if="formData.seckillGoods.limitBuy" -->
          <div class="item" v-if="false">
            <span><i>*</i>限购数量</span>
            <div class="content">
              <el-input
                  v-model="formData.seckillGoods.limitNum"
                  @keyup="stockChange"
                  placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="mask" v-if="pageType == 1"></div>
    <el-button
        v-if="pageType != 1"
        type="primary"
        class="productbtn"
        @click="submit"
    >
      保存
    </el-button>
    <el-image-viewer
        v-if="goodsImageList.length"
        :url-list="goodsImageList"
        hide-on-click-modal
        @close="handleClose"
    />
  </div>
</template>

<script setup>
import {reactive, toRefs, ref, onMounted} from 'vue'
import draggable from 'vuedraggable'
import fileUpload from '@/components/fileUpload'
import {ElMessage} from 'element-plus'
import {goodsCategoryTree} from '@/api/productManagement/productClassify'
import {
  goodsCreate,
  goodsUpdate,
  goodsDetail,
  queryLevel
} from '@/api/productManagement/productList'
import {queryCriteria} from '@/api/cardManagement/platform'
import {useRouter} from 'vue-router'

const router = useRouter()
const quillRef = ref()
const uploadFilesDom = ref(null)
onMounted(async () => {
  getLevel()
  getGoodsCategoryTree()
  if (router.currentRoute.value.query.id) {
    formData.value.id = router.currentRoute.value.query.id
    pageType.value = router.currentRoute.value.query.type
    await remoteMethod()
    setTimeout(() => {
      getGoodsDetail()
    }, 500)
  }
})
const initData = reactive({
  activeName: 'basicSettings',
  editorOption: {
    modules: {
      // 工具栏的具体配置
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{header: 1}, {header: 2}],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}],
        [{color: []}, {background: []}],
        ['clean']
      ]
    },
    placeholder: '请输入内容...'
  },
  formData: {
    code: '',
    name: '',
    description: '',
    categoryIdList: [],
    goodsImageList: [],
    level: '0',
    specType: 1,
    stockWarning: '',
    virtualSalesNum: '',
    payType: 1,
    type: 1,
    deliveryType: 1,
    pickUpAddress: '',
    expressTemplateId: '',
    sort: '',
    content: '',
    goodsSku: {
      goodsId: '',
      price: 0,
      linePrice: 0,
      marketPrice: 0,
      stock: 0,
      weight: 0,
      volume: 0,
      code: '',
      id: ''
    },
    propertyList: [],
    id: '',
    wuliuway: [1],
    seckillGoods: {
      id: '',
      limitTime: false,
      time: [],
      limitTimeStart: '',
      limitTimeEnd: '',
      limitBuy: false
    },
    refId: '',
    refContent: ''
  },
  shopTypeList: [
    {
      label: '现金商品',
      value: 1,
      disabled: false
    },
    {
      label: '积分商品',
      value: 2,
      disabled: false
    },
    {
      label: '有赞商品',
      value: 3,
      disabled: false
    },
    {
      label: '虚拟券',
      value: 4,
      disabled: false
    }
  ],
  payTypeList: [
    {
      label: '现金支付',
      value: 1,
      disabled: false
    },
    {
      label: '积分支付',
      value: 2,
      disabled: true
    },
    {
      label: '混合支付',
      value: 3,
      disabled: false
    }
  ],
  levelList: [],
  deliveryTypeList: [],
  treeList: [],
  cateOpt: {
    multiple: true,
    checkStrictly: true,
    label: 'name',
    value: 'id',
    children: 'children',
    emitPath: false
  },
  pageType: 3,
  goodsImageList: [],
  editImgIndex: -1,
  editflag: false,
  refIdOptions: [],
  sendTotalType: ''
})
const {
  activeName,
  editorOption,
  formData,
  payTypeList,
  shopTypeList,
  deliveryTypeList,
  treeList,
  cateOpt,
  pageType,
  goodsImageList,
  editImgIndex,
  editflag,
  refIdOptions,
  sendTotalType,
  levelList
} = toRefs(initData)
// 返回上一级、取消
const cancel = () => {
  router.push('/productList')
}

const getLevel = () => {
  queryLevel().then(({data: res}) => {
    if (res.code == 200) {
      levelList.value = res.data
    } else {
      ElMessage.warning(res.msg)
    }
  })
}

// 数字验证
const priceChange = e => {
  e.target.value = e.target.value.replace(/[^\d.]/g, '') //清除“数字”和“.”以外的字符
  e.target.value = e.target.value.replace(/^\./g, '') //验证第一个字符是数字而不是.
  e.target.value = e.target.value.replace(/\.{2,}/g, '.') //只保留第一个. 清除多余的.
  e.target.value = e.target.value
      .replace('.', '$#$')
      .replace(/\./g, '')
      .replace('$#$', '.')
}

const priceBlur = e => {
  e.target.value = Number(e.target.value).toFixed(2)
}

// 纯数字验证
const stockChange = e => {
  e.target.value = e.target.value.replace(/[^\d]/g, '')
}

// 预览图片
const handleClose = () => {
  goodsImageList.value = []
}

const openImagePreview = index => {
  goodsImageList.value = [formData.value.goodsImageList[index]]
}

// 修改
const editImage = index => {
  editImgIndex.value = index
  uploadFilesDom.value.$el.querySelector('input').click()
  editflag.value = true
}

const gotofeishu = () => {
  window.open(
      'https://reimagine.feishu.cn/docx/XufZdmjSdokRLUxFXT7c6miJnSf?from=from_copylink',
      '_blank'
  )
}

// 添加商品属性
const addJF = () => {
  if (formData.value.propertyList.length == 0) {
    formData.value.propertyList.push({
      label: '',
      value: '',
      sort: '',
      type: 'text'
    })
  } else {
    let flag = formData.value.propertyList.some(item => {
      return item.label == '' || item.value === ''
    })
    if (flag) {
      ElMessage.warning('请填写商品属性信息')
    } else {
      formData.value.propertyList.push({
        label: '',
        value: '',
        sort: '',
        type: 'text'
      })
    }
  }
}

const handleChangeType = val => {
  if (val == 1 || val == 4) {
    formData.value.payType = 1
    payTypeList.value.forEach(item => {
      item.disabled = false
      if (item.value == 2 && val == 1) {
        item.disabled = true
      }
    })
  } else if (val == 2) {
    formData.value.payType = 2
    payTypeList.value.forEach(item => {
      item.disabled = false
      if (item.value == 1) {
        item.disabled = true
      }
    })
  } else {
    formData.value.payType = 1
    payTypeList.value.forEach(item => {
      item.disabled = false
    })
  }
  if (val == 4) {
    formData.value.deliveryType = 3
  } else {
    formData.value.deliveryType = 1
  }
}

// 删除商品属性
const deleteJF = index => {
  formData.value.propertyList = formData.value.propertyList.filter(
      (item, i) => {
        return i != index
      }
  )
}

// 获取数据详情
const getGoodsDetail = () => {
  goodsDetail({id: formData.value.id}).then(({data: res}) => {
    if (res.code == 200) {
      formData.value = res.data
      formData.value.level = String(res.data.levelLimit)
      formData.value.goodsImageList = res.data.goodsImageList || []
      formData.value.propertyList = res.data.propertyList || []
      formData.value.seckillGoods = res.data.seckillGoods || {
        id: '',
        limitTime: false,
        time: [],
        limitTimeStart: '',
        limitTimeEnd: '',
        limitBuy: false
      }
      formData.value.seckillGoods.time = [
        res.data.seckillGoods.limitTimeStart,
        res.data.seckillGoods.limitTimeEnd
      ]
      if (res.data.type == 4) {
        refIdOptions.value.forEach(item => {
          if (item.id == res.data.refId) {
            sendTotalType.value = item.sendTotalType
          }
        })
      }
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const limitTimechange = () => {
  formData.value.seckillGoods.time = []
}

const limitBuychange = () => {
  formData.value.seckillGoods.limitNum = ''
}
// 获取列表数据
const getGoodsCategoryTree = () => {
  goodsCategoryTree().then(({data: res}) => {
    if (res.code == 200) {
      treeList.value = res.data
    } else {
      ElMessage.error(res.msg)
      treeList.value = []
    }
  })
}

// 支付方式切换 运费设置禁用
const payTypeChange = val => {
  if (val == 2 && formData.value.type != 4) {
    formData.value.deliveryType = 1
  }
  if (val == 2 && formData.value.type == 4) {
    formData.value.deliveryType = 3
  }
}

// 轮播图
const getimglist = val => {
  if (formData.value.goodsImageList.length < 10) {
    if (editImgIndex.value != -1) {
      formData.value.goodsImageList[editImgIndex.value] = val
      editImgIndex.value = -1
      editflag.value = false
    } else {
      formData.value.goodsImageList.push(val)
    }
  }
  if (editflag.value) {
    if (editImgIndex.value != -1) {
      formData.value.goodsImageList[editImgIndex.value] = val
      editImgIndex.value = -1
      editflag.value = false
    }
  }
}

// 删除图片
const deleteImage = index => {
  formData.value.goodsImageList = formData.value.goodsImageList.filter(
      (item, i) => i != index
  )
}

// 保存
const submit = () => {
  if (formData.value.code == '') {
    ElMessage.warning('请输入商品货号')
    return false
  }
  if (formData.value.name == '') {
    ElMessage.warning('请输入商品名称')
    return false
  }
  if (formData.value.type == 3 && formData.value.refContent == '') {
    ElMessage.warning('请输入有赞商品跳转路径')
    return false
  }
  if (formData.value.type == 4 && formData.value.refId == '') {
    ElMessage.warning('请关联虚拟券')
    return false
  }
  if (formData.value.categoryIdList.length == 0) {
    ElMessage.warning('请选择商品分类')
    return false
  }
  if (formData.value.level == '') {
    ElMessage.warning('请选择购买等级限制')
    return false
  }
  if (formData.value.goodsImageList.length == 0) {
    ElMessage.warning('请选择商品轮播图')
    return false
  }
  if (formData.value.specType == '') {
    ElMessage.warning('请选择商品规格')
    return false
  }
  if (formData.value.payType == '') {
    ElMessage.warning('请选择支付方式')
    return false
  }
  if (formData.value.goodsSku.price == '') {
    ElMessage.warning('请输入商品价格')
    return false
  }
  if (Number(formData.value.goodsSku.price) > 100000) {
    ElMessage.warning('商品价格不能大于10万元')
    return false
  }
  if (formData.value.goodsSku.stock == '' && formData.value.type != 4) {
    ElMessage.warning('请输入库存')
    return false
  }
  if (
      formData.value.goodsSku.stock == '' &&
      formData.value.type == 4 &&
      sendTotalType.value == ''
  ) {
    ElMessage.warning('请输入库存')
    return false
  }
  if (formData.value.deliveryType == '') {
    ElMessage.warning('请输入运费设置')
    return false
  }
  if (formData.value.deliveryType == 2 && formData.value.pickUpAddress == '') {
    ElMessage.warning('请输入取餐地点')
    return false
  }
  if (!regExp(quillRef.value.getText())) {
    ElMessage.warning('请输入商品详情')
    return false
  }
  // if (
  //   formData.value.seckillGoods.limitTime &&
  //   formData.value.seckillGoods.time.length == 0
  // ) {
  //   ElMessage.warning('请选择限时时间')
  //   return false
  // }
  // if (formData.value.seckillGoods.limitBuy && formData.value.seckillGoods.limitBuy == '') {
  //   ElMessage.warning('请输入限购数量')
  //   return false
  // }
  let data = {
    code: formData.value.code,
    name: formData.value.name,
    categoryIdList: formData.value.categoryIdList,
    goodsImageList: formData.value.goodsImageList,
    specType: formData.value.specType,
    goodsSku: {
      price: Number(formData.value.goodsSku.price),
      stock: Number(formData.value.goodsSku.stock)
    },
    payType: formData.value.payType,
    deliveryType: formData.value.deliveryType,
    content: formData.value.content,
    type: formData.value.type,
    levelLimit: formData.value.level
    // seckillGoods: {
    //   limitTime: formData.value.seckillGoods.limitTime,
    //   limitBuy: formData.value.seckillGoods.limitBuy
    // }
  }
  // if (formData.value.seckillGoods.limitTime) {
  //   data.seckillGoods.limitTimeStart = formData.value.seckillGoods.time[0]
  //   data.seckillGoods.limitTimeEnd = formData.value.seckillGoods.time[1]
  // }
  // if (formData.value.seckillGoods.limitBuy) {
  //   data.seckillGoods.limitNum = formData.value.seckillGoods.limitNum
  // }
  if (formData.value.type == 4) {
    data.refId = formData.value.refId
  }
  if (formData.value.type == 3) {
    data.refContent = formData.value.refContent
  }
  if (formData.value.deliveryType == 2) {
    data.pickUpAddress = formData.value.pickUpAddress
  }
  if (formData.value.propertyList.length > 0) {
    formData.value.propertyList.forEach((item, i) => {
      item.sort = formData.value.propertyList.length - 1 - i
    })
    data.propertyList = formData.value.propertyList
  }
  if (formData.value.goodsSku.linePrice != '') {
    data.goodsSku.linePrice = Number(formData.value.goodsSku.linePrice)
  }
  if (formData.value.goodsSku.marketPrice != '') {
    data.goodsSku.marketPrice = Number(formData.value.goodsSku.marketPrice)
  }
  if (formData.value.goodsSku.volume != '') {
    data.goodsSku.volume = Number(formData.value.goodsSku.volume)
  }
  if (formData.value.goodsSku.weight != '') {
    data.goodsSku.weight = Number(formData.value.goodsSku.weight)
  }
  if (formData.value.goodsSku.code != '') {
    data.goodsSku.code = formData.value.goodsSku.code
  }
  if (formData.value.stockWarning != '') {
    data.stockWarning = Number(formData.value.stockWarning)
  }
  if (formData.value.virtualSalesNum != '') {
    data.virtualSalesNum = Number(formData.value.virtualSalesNum)
  }
  if (formData.value.sort != '') {
    data.sort = formData.value.sort
  }
  if (formData.value.id == '') {
    goodsCreate(data).then(({data: res}) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/productList')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  } else {
    data.id = formData.value.id
    data.goodsSku.goodsId = formData.value.goodsSku.goodsId
    data.goodsSku.id = formData.value.goodsSku.id
    data.goodsSku.code = formData.value.goodsSku.code
    data.goodsSku.linePrice = formData.value.goodsSku.linePrice
    data.goodsSku.marketPrice = formData.value.goodsSku.marketPrice
    data.goodsSku.volume = formData.value.goodsSku.volume
    data.goodsSku.weight = formData.value.goodsSku.weight
    // data.seckillGoods.id = formData.value.seckillGoods.id
    goodsUpdate(data).then(({data: res}) => {
      if (res.code == 200) {
        ElMessage.success(res.msg)
        setTimeout(() => {
          router.push('/productList')
        }, 1000)
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

// 富文本验证
const regExp = str => {
  const pattern_Ch = new RegExp('[\u4E00-\u9FA5]')
  const pattern_En = new RegExp('[A-Za-z]')
  const pattern_Num = new RegExp('[0-9]')
  const pattern_Sy = new RegExp(
      "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/\\?~！@#￥……&*（）——|{}【】‘；：”“'。，、？+-/]|[\\\\/]"
  )
  return (
      pattern_Ch.test(str) ||
      pattern_En.test(str) ||
      pattern_Sy.test(str) ||
      pattern_Num.test(str)
  )
}

// 可按名称模糊匹配
const remoteMethod = query => {
  // if (query) {
  let data = {
    current: 1,
    size: 99999,
    keyword: query,
    state: 2,
    getType: 1
  }
  queryCriteria(data).then(({data: res}) => {
    if (res.code == 200) {
      refIdOptions.value = res.data.records
      if (router.currentRoute.value.query.id) {
        // getGoodsDetail()
      }
    } else {
      refIdOptions.value = []
      ElMessage.error(res.msg)
    }
  })
  // } else {
  //   refIdOptions.value = []
  // }
}

const handleChangeRefId = val => {
  let item = refIdOptions.value.filter(item => item.id == val)
  sendTotalType.value = item[0].sendTotalType
  formData.value.goodsSku.stock = item[0].sendTotal
}
</script>
<style lang="scss" scoped>
.productItem {
  position: relative;

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 84px;
    background: transparent;
    z-index: 10;
  }
}

.productform {
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > span {
      width: 120px;
      text-align: right;
      margin-right: 10px;
      font-size: 14px;

      i {
        color: red;
      }
    }

    :deep(.content) {
      // width: 300px;

      & > span {
        font-size: 12px;
      }

      .el-input,
      .el-select,
      .el-cascader,
      .el-textarea {
        width: 300px;
      }
    }
  }

  .otherItem {
    align-items: flex-start;

    .content {
      display: flex;
    }
  }

  .swiperContent {
    width: calc(100% - 130px) !important;
  }

  .imglist {
    & > div {
      max-width: 1000px;
      margin-right: 5px;
      display: flex;
      flex-wrap: wrap;
    }

    .itemImg {
      width: 188px;
      height: 188px;
      position: relative;
      margin-right: 10px;
      background: #f2f2f2;
      margin-bottom: 10px;

      .img {
        width: 100%;
        height: 100%;
      }

      .change {
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;

        span {
          flex: 1;
          text-align: center;
          cursor: pointer;
        }
      }

      .deleteimg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: -6px;
        right: -6px;
        z-index: 10;
        color: red;
        cursor: pointer;
        font-size: 24px;
        display: none;
      }

      &:hover {
        cursor: move;

        .deleteimg {
          display: block;
        }

        .change {
          display: flex;
        }
      }
    }
  }

  .uploadtips {
    width: 520px !important;
    font-size: 14px;
    color: #999999;
  }

  .upload {
    width: 188px;
    height: 188px;
  }

  .delivery {
    align-items: flex-start;

    .form-tips {
      color: #999999;
      font-size: 12px;
    }

    span {
      margin-top: 10px;
    }
  }

  :deep(.prdouctcategory) {
    width: 100%;
  }
}

.productbtn {
  display: block;
  margin: 0 auto;
}

.detailsform {
  margin-bottom: 30px;

  .item {
    width: 100%;
    display: flex;
    align-items: flex-start;

    & > span {
      font-size: 14px;
      margin-right: 20px;

      i {
        color: red;
      }
    }
  }

  .content {
    width: 40%;
    height: 400px;
  }

  .contenthtml {
    width: 40%;
    height: 466px;
    background: #fff;
    margin-left: 20px;
    border: 15px solid #ccc;
    padding-left: 20px;
    padding-top: 20px;
  }

  .item {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .addhj {
      color: #409eff;
      cursor: pointer;
    }

    .dialog_input {
      width: 200px;
      margin-right: 20px;
    }

    .cont {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: move;

      & > span {
        width: auto;
        font-size: 12px;
      }

      .delete {
        color: red;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .property {
    margin-bottom: 30px;

    .content {
      height: auto;
    }
  }
}
</style>
